<template>
  <div class="page">
    <van-cell-group title="常用命令">
      <div class="p-3">
        <p>您可以直接发送以下命令获得相关功能</p>
        <p class="d-flex justify-content-between">
          <van-tag color="#7232dd" plain>模型</van-tag>
          <van-tag color="#7232dd" plain>角色</van-tag>
          <van-tag color="#7232dd" plain>绘画</van-tag>
          <van-tag color="#7232dd" plain>充值</van-tag>
          <van-tag color="#7232dd" plain>推广</van-tag>
          <van-tag color="#7232dd" plain>设置</van-tag>
          <van-tag color="#7232dd" plain>客服</van-tag>
          <van-tag color="#7232dd" plain>帮助</van-tag>
        </p>
      </div>
    </van-cell-group>
    <van-cell-group title="如何使用ChatGPT">
      <div class="p-3">
        <p>1. 直接发送文字即可与GPT对话</p>
        <p>2. 进入<a href="/nijibot/settings/chatgpt">设置</a>页面可以设置版本和参数, 支持GPT3.5, GPT4</p>
        <p>3. 如果没有回答完, 请输入<span>继续</span>即可接着回答, 注意: 如果历史条数设置为0则不起作用</p>
        <p>4. 降低<span>最大返回</span>和<span>历史记录</span>可以降低Token消耗, 但是会影响回答质量, 推荐<a href="/nijibot/subscribe">订阅</a>会员, 享受不限对话次数, 不限Token数量</p>
      </div>
    </van-cell-group>
    <van-cell-group title="如何使用Midjourney绘画">
      <div class="p-3">
        <p>1. 要使用『文生图』, 请直接发送: <span>MJ:文字描述</span>, 例如: MJ:一个可爱的亚洲女孩</p>
        <p>2. 要使用『图生图』, 请直接发送图片, 然后根据提示进行后续操作即可, 此功能可以制作<span>真人动漫</span></p>
        <p>3. 在<a href="/nijibot/settings/midjourney">设置</a>中可以设置Midjourney版本, 支持最新的5.2和动漫版本Niji5</p>
        <p>4. 推荐提示词使用<span>英文</span>, 也可以使用中文, 但是效果没有英文好, 可能出现不符合预期的绘图</p>
        <p>5. 可以去网上搜索怎么写提示或者查看我们公众号文章的的Midjourney画廊参考着改写</p>
      </div>
    </van-cell-group>
    <van-cell-group title="关于Token费用">
      <div class="p-3">
        <p>使用GPT对话功能按实际用量扣取, Midjourney绘画10000Tokens一次(文生图/图生图), 出图4张, 重绘5000Tokens一张, 放大图像免费</p>
        <p>你可以在此<a href="/nijibot/recharge">充值</a>Token, 你还可以参与推广计划, 免费获取Token, 发送<span>海报</span>给公众号, 获取详情</p>
        <p>你可以在此<a href="/nijibot/subscribe">订阅</a>包时会员, 享受无限次数, 不限Token的ChatGPT3.5对话服务</p>
      </div>
    </van-cell-group>
    <van-cell-group title="联系客服">
      <div class="p-3 text-center">
        <p>长按识别二维码添加客服</p>
        <img src="https://qiufo.oss-cn-shenzhen.aliyuncs.com/images/nijibro_qrcode.jpg" class="w-50" alt="">
        <p>客服微信: <span>NijiBro</span></p>
        <p><span @click="logout">点此刷新个人数据</span></p>
      </div>
    </van-cell-group>
    <div class="py-4 text-center text-muted">
      NijiBot v1.0.1
    </div>
  </div>
</template>

<script>
import { Tag, Toast } from 'vant';
import { hideWechatOptionMenu } from "@/utils/util";
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import { LOGOUT } from "@/store/modules/auth";
export default {
  components: {
    [Tag.name]: Tag,
  },
  setup() {
    const store = useStore();
    onMounted(() => {
      hideWechatOptionMenu();
    });
    const logout = () => {
      store
        .dispatch(LOGOUT)
        .then(() => {
          Toast.success('操作成功');
          setTimeout(() => {
            window.location.reload();
          }, 2500);
        })
        .catch((error) => {
          Toast(error);
        });
    }
    return { logout }
  }
};
</script>

<style scoped>
.p-3 p:last-child {
  margin-bottom: 0;
}

a,
p span {
  color: #7232dd;
}
</style>